import { Button, Card, CardContent, CardHeader, Grid, InputAdornment, TextField } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useState, useEffect } from "react";
import { QRCode } from 'react-qrcode-logo';
import toastr from "toastr";
import "toastr/build/toastr.css";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Alert, AlertTitle } from '@material-ui/lab';
import jwt_decode from "jwt-decode";
import Timer from "./timer";
const TronGrid = require('trongrid');
const TronWeb = require('tronweb');
var sourceAddress = "";
var currency = "";
let orgAmount = 0;
let transInfo = {};
var token = process.env.REACT_APP_TOKEN;
var curScriptId = "Waiting For Payment";
var ackId = '';

function Dashboard() {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenInfo = urlParams.get('token');
    const dataInfo = urlParams.get('data');
    let seconds = 0;
    try {
        transInfo = jwt_decode(tokenInfo);
        let curAddress = jwt_decode(dataInfo);
        sourceAddress = curAddress.address;
        currency = transInfo.type;
        orgAmount = currency.toLowerCase() === 'trx' ? parseFloat(curAddress.coins).toFixed(6) : curAddress.coins;
        ackId = curAddress.ackId;
        const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
        const endTime = transInfo.exp; // use UNIX timestamp in seconds
        seconds = endTime - stratTime;
    }
    catch (e) {
        console.log(e);
    }

    const [transactionId, setTransactionId] = useState("Waiting For Payment");
    const [status, setStatus] = useState("Waiting For Payment");
    const [amount, setAmount] = useState(orgAmount);
    const [confirmations, setConfirmations] = useState([]);
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft || transactionId !== "Waiting For Payment") {
            setTimeLeft(0);
            return;
        };

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    useEffect(() => {
        if (orgAmount !== 0 && currency.toLowerCase() === "btc") {
            var ws = new WebSocket("wss://testnet-ws.smartbit.com.au/v1/blockchain");
            //var ping = pinger(ws);

            ws.onmessage = function (event) {
                let curData = JSON.parse(event.data);
                switch (curData.type) {
                    case 'subscribe-response':
                        log("Transaction linked with block chain. Please make payment soon");
                        break;
                    case 'heartbeat':
                        log("We are waiting for your payment");
                        break;
                    case 'address':
                        log("We got some transaction event. We are verifying.");
                        break;
                    default:
                        log(event.data);
                        break;
                }

                if (curData.type === "address") {
                    if (curData.payload && curData.payload.transaction) {
                        if (curData.payload.transaction.confirmations <= 0) {
                            let isExists = curData.payload.transaction.outputs.filter((x) => x.addresses.indexOf(sourceAddress) > -1);
                            if (isExists.length > 0) {
                                let confromAmt = isExists[0].value;
                                if (confromAmt.toString() === orgAmount.toString()) {
                                    setAmount(confromAmt);
                                    curScriptId = curData.payload.transaction.txid;
                                    setTransactionId(curData.payload.transaction.hash);
                                    fetch(process.env.REACT_APP_API + ackId + '?hash=' + curData.payload.transaction.hash, {
                                        method: 'PUT',
                                        headers: {
                                            'Authorization': 'Bearer ' + tokenInfo
                                        }
                                    });

                                    toastr.success("Transaction Initiated Waiting for the Block Chain to Confirm");
                                    setStatus("Transaction Initiated Waiting for the Block Chain to Confirm");
                                    //ping.stop();
                                    ws.send(JSON.stringify({ type: "address", address: sourceAddress, unsubscribe: true }));
                                    ws.close();
                                }
                            }
                        }
                    }
                }
            }

            ws.onopen = function (event) {
                ws.send(JSON.stringify({ type: "address", address: sourceAddress }));
            }
        }
        else if (orgAmount !== 0 && currency.toLowerCase() === "trx") {
            log("Transaction linked with block chain. Please make payment soon");
            const tronWeb = new TronWeb({
                fullHost: 'https://api.shasta.trongrid.io'
            });
            log("We are waiting for your payment");
            const tronGrid = new TronGrid(tronWeb);
            tronGrid.setExperimental("3b913ed3-d308-437d-b940-83f1e4958d02");

            let counter = 1;


            let cnt = setInterval(async () => {
                counter++;
                if (counter === 28) {
                    clearInterval(cnt);
                }
                await getTransactions();
                log("We are waiting for your payment");
            }, 20000)

            async function getTransactions() {
                const address = sourceAddress;

                const options = {
                    onlyTo: true,
                    limit: 10,
                    orderBy: 'timestamp,asc',
                    minBlockTimestamp: Date.now() - 60000 // from a minute ago to go on
                };

                // promise
                tronGrid.account.getTransactions(address, options).then(transactions => {
                    transactions.data.forEach((tran) => {
                        tran.raw_data.contract.forEach((ct) => {
                            if ((ct.parameter.value.amount / 1000000).toFixed(6) === parseFloat(orgAmount).toFixed(6)) {
                                clearInterval(cnt);
                                log("We got some transaction event. We are verifying.");
                                setAmount(parseFloat(orgAmount).toFixed(6));
                                curScriptId = tran.txID;
                                setTransactionId(tran.txID);
                                fetch(process.env.REACT_APP_API + ackId + '?hash=' + tran.txID + '&fromAddress=' + tronWeb.address.fromHex(ct.parameter.value.owner_address), {
                                    method: 'PUT',
                                    headers: {
                                        'Authorization': 'Bearer ' + tokenInfo
                                    }
                                });

                                toastr.success("Transaction Initiated Waiting for the Block Chain to Confirm");
                                setStatus("Transaction Initiated Waiting for the Block Chain to Confirm");
                                return;
                            }
                        })
                    })
                }).catch(err => console.error(err));
            }


        }
    }, []);

    if (orgAmount === 0) {
        return <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            You are Not Authorised to View this Page — <strong>check it out!</strong>
        </Alert>;
    }

    if (transactionId === "Waiting For Payment" && timeLeft <= 0) {
        return <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Session Expired — <strong>check it out!</strong>
        </Alert>;
    }

    if (transactionId !== "Waiting For Payment") {
        if (currency.toLowerCase() === "btc") {
            return <Alert severity="success">
                <AlertTitle>Payment Success</AlertTitle>
                Your Transaction is Confirmed with transaction hash&nbsp;&nbsp;&nbsp;
                <a style={{ color: "white", wordBreak: "break-word" }}
                    href={"https://www.blockchain.com/btc-testnet/tx/" + transactionId}>{transactionId}</a>
                <br /><strong>For more information&nbsp;&nbsp;&nbsp;
                    <a style={{ color: "white" }}
                        href={"https://www.blockchain.com/btc-testnet/tx/" + transactionId}>Click Here</a>
                </strong>
            </Alert>;
        }
        else {
            return <Alert severity="success">
                <AlertTitle>Payment Success</AlertTitle>
                Your Transaction is Confirmed with transaction hash&nbsp;&nbsp;&nbsp;
                <a style={{ color: "white", wordBreak: "break-word" }}
                    href={"https://shasta.tronscan.org/#/transaction/" + transactionId}>{transactionId}</a>
                <br /><strong>For more information&nbsp;&nbsp;&nbsp;
                    <a style={{ color: "white" }}
                        href={"https://shasta.tronscan.org/#/transaction/" + transactionId}>Click Here</a>
                </strong>
            </Alert>;
        }
    }


    function log(msg) {
        console.log(msg);
        var node = document.createElement("LI");
        if (node) {             // Create a <li> node
            var textnode = document.createTextNode(msg);
            if (node && node.appendChild) {    // Create a text node
                node.appendChild(textnode);
            }
            if (document.getElementById("log")) {
                document.getElementById("log").appendChild(node);
            }
        }
    }

    // function pinger(ws) {
    //     var timer = setInterval(function () {
    //         if (ws.readyState == 1) {
    //             ws.send(JSON.stringify({ op: "ping" }));
    //         }
    //     }, 5000);
    //     return { stop: function () { clearInterval(timer); } };
    // }

    function copyText() {
        var copyText = document.getElementById("myInput");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");
        toastr.success("Link Copied to Clipboard");
    }

    return (
        <Card>
            <CardHeader title={"Coins : " + amount + " " + currency}
                subheader={"Transaction Status : " + transactionId} />
            <CardContent>

                <Grid container>
                    <Grid item md={6} sm={12} xs={12} style={{ "textAlign": "center" }}>
                        <QRCode value={currency.toLowerCase() === "btc" ? "bitcoin:" : "" + sourceAddress + "?amount=" + amount + "&label=payment"} />
                        <TextField
                            variant="standard"
                            fullWidth
                            value={sourceAddress}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FileCopyIcon htmlColor="#b27919" onClick={() => copyText()} />
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                "aria-readonly": true,
                                id: "myInput"
                            }} />

                        {
                            status === "Transaction Confirmed By Block Chain" ?
                                <Alert severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    Transaction Confirmed — <strong>check it out!</strong>
                                </Alert>
                                :
                                <div>
                                    <br />
                                    <br />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AutorenewIcon />}
                                    >
                                        {status}
                                    </Button>
                                    <br />
                                    <br />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<AutorenewIcon />}
                                    >
                                        {curScriptId}
                                    </Button>
                                </div>
                        }
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <ul className="details">
                            <li><label>Name: </label><b>{transInfo.name}</b></li>
                            <li><label>Amount:  </label><b>{transInfo.amount} USD</b></li>
                            <li><label>Customer ID:  </label><b>{transInfo.cid}</b></li>
                            <li><label>Email:  </label><b>{transInfo.email}</b></li>
                            <li><label>Mobile No: </label><b>{transInfo.mobile}</b></li>
                            <li><label>Reference No: </label><b>{transInfo.txnid}</b></li>
                        </ul>
                        {
                            status === "Waiting For Payment" ?
                                <Timer endTime={transInfo.exp} /> : <span />
                        }

                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <ul id="log">
                        </ul>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default Dashboard;
